import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { graphql, Link } from 'gatsby';
export const pageQuery = graphql`
  query {
    allMdx(
      filter: {frontmatter: {type: {eq: "subcategory"}}, fileAbsolutePath: {regex: "/construction-management/"}}
    )
    
    {
      edges {
        node {
          frontmatter {
            type
            title
          }
          slug
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Construction Management`}</h1>
    <h2>{`Project and contract management lectures`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/introduction-to-project-and-contract-management.pdf"
      }}>{`Introduction to project and contract management`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Value-Management-VM.pdf"
      }}>{`Value Management (VM)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Typical-Contract-Conditions.pdf"
      }}>{`Typical Contract Conditions`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Tendering-process-in-Civil-Engineering.pdf"
      }}>{`Tendering process in Civil Engineering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/technical-report-writing.pdf"
      }}>{`Technical report writing`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/How-to-Write-a-Technical-Report.pdf"
      }}>{`How to Write a Technical Report`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Preparations-of-Tender-Documents.pdf"
      }}>{`Preparations of Tender Documents`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Personal-Protective-Equipment-PPE-for-construction.pptx"
      }}>{`Personal Protective Equipment (PPE) for construction`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/P_CM-LEC03.ppt"
      }}>{`PCM-LEC03`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/P_CM-LEC02.ppt"
      }}>{`PCM-LEC02`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/P_CM-LEC01.ppt"
      }}>{`PCM-LEC01`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/P_CM_-_Legal_Framework.pdf"
      }}>{`PCM_-_Legal_Framework`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Negotiation.pdf"
      }}>{`Negotiation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Introduction_to_Engineering_Economics.pdf"
      }}>{`Introduction_to_Engineering_Economics`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Engineering-Economics-Money-Time-Relationship.pdf"
      }}>{`Engineering Economics Money-Time Relationship`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Dispute-Resolution-in-Construction-Industry.pdf"
      }}>{`Dispute Resolution in Construction Industry`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Contracts-andPre-Qualification-Of-Contracts.pdf"
      }}>{`Contracts andPre Qualification Of Contracts`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Contract.pdf"
      }}>{`Contract`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Civil-Engineering-Contracts.pdf"
      }}>{`Civil Engineering Contracts`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/An-Introduction-to-construction-Safety.pptx"
      }}>{`An Introduction to construction Safety`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/CONSTRUCTION-Site-SAFETY-ENVIRONMENT-MANAGEMENT.pdf"
      }}>{`CONSTRUCTION Site SAFETY & ENVIRONMENT MANAGEMENT`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Construction-Safety-Various-Part-2.pptx"
      }}>{`Construction Safety Various Part 2`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Construction-Safety-Various-Part-1.pptx"
      }}>{`Construction Safety Various Part 1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Construction-Safety-in-Pakistan.pptx"
      }}>{`Construction Safety in Pakistan`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/CONSTRUCTION-SAFETY-ENVIRONMENT-MANAGEMENT.pdf"
      }}>{`CONSTRUCTION SAFETY & ENVIRONMENT MANAGEMENT`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/complaint-mechanism.pdf"
      }}>{`Complaint mechanism`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Civil-Engineering-Procurement.pdf"
      }}>{`Civil Engineering Procurement`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/center-line-method-of-estimation.pdf"
      }}>{`center line method of estimation`}</a></p>
    <h2>{`Engineering economy and construction management books`}</h2>
    <h2>{`Engineering economy and construction management lectures`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Introduction-to-project-management.pdf"
      }}>{`Introduction to project management`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Chapter-1-Engineering-Economy.pdf"
      }}>{`Chapter 1 Engineering Economy`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Chapter-2-Factors-Effect-of-Time-Interest-on-Money.pdf"
      }}>{`Chapter 2 – Factors, Effect of Time & Interest on Money`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Chapter-3-Combining-Factors.pdf"
      }}>{`Chapter 3 – Combining Factors`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Chapter_5_-_Present_Worth_Analysis.pdf"
      }}>{`Chapter`}<em parentName="a">{`5`}</em>{`-_Present_Worth_Analysis`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Chapter_6_-_Annual_Worth_Analysis.pdf"
      }}>{`Chapter`}<em parentName="a">{`6`}</em>{`-_Annual_Worth_Analysis`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Chapter_9_-_Benefit_Cost_Analysis.pdf"
      }}>{`Chapter`}<em parentName="a">{`9`}</em>{`-_Benefit_Cost_Analysis`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Chapter_11_-_Replacement_Retention_Decisions.pdf"
      }}>{`Chapter`}<em parentName="a">{`11`}</em>{`-_Replacement_Retention_Decisions`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Introduction_to_Engineering_Economics-1.pdf"
      }}>{`Introduction_to_Engineering_Economics`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/PMBOK.pdf"
      }}>{`PMBOK`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Quality-Management-ISO9000.pdf"
      }}>{`Quality Management ISO9000`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation1.netlify.app/wp-content/uploads/2020/02/Quality-Management.pdf"
      }}>{`Quality Management`}</a></p>
    <div>
      {props.data.allMdx.edges.map(x => <Link to={'/' + x.node.slug} key={x.node.frontmatter.title} mdxType="Link">
            <div>
              {x.node.frontmatter.title}
            </div>
          </Link>)}
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      